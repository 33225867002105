*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  user-select: none;
}

/* Background For body */



/* CSS for Show More Button */

.cssbuttons-io-button {
  background: #a370f0;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  cursor: pointer;
}

.cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
  right: 0.3em;
  transition: all 0.3s;
}

#svg1{
transform:rotate(90deg);
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon #svg1 {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
}

.cssbuttons-io-button:hover .icon #svg1 {
  transform: translateX(0.1em);
  transform:rotate(90deg);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}


/* Button Css Ends  */


/* Navigation Goes Here */

#logo{
  height: 75px;
  width: 75px;
  border-radius: 50%;
}

#navbar{
  /* position: fixed; */
  /* width: 100%; */
  /* background-color: red; */
  padding: 10px;
  display: flex;
  align-items: center;
}

#navContainer{
  /* background-color: blue; */
  display: inline-block;
  padding: 10px;
  position: absolute;
  /* display: none; */
  right: 40px;
}

#navContainer ul li{
  list-style-type: none;
  display: inline-block;
}
#navContainer ul li a{
  text-decoration: none;
  color: black;
  margin-left: 19px;
  margin-right: 10px;
  font-size: 19px;
  padding: 12px;
  /* background-color: brown; */
  display: inline-block;
}

#navContainer ul li a:hover{
  color: rgb(29, 151, 189);
  animation: jello;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
}

/* Navigation Ends Here */

/* Mobile Navigation  */
#mobileNav{
  /* background-color: rgb(118, 23, 23); */
  text-align: center;
  display: none;
}
#mobileNav li {
  list-style-type: none;
  line-height: 2;
}

#mobileNav li a{
  text-decoration: none;
  color: black;
  /* padding: 10px; */
  font-size: 18px;
}

#mobileNav li a:hover{
  color: blueviolet;
  
}

#menuButton{
  font-size: 30px;
  position: absolute;
  right: 20px;
  cursor: pointer;
  display: none
}

/* Mobile Navigation Ends Here */

/* Media Query for Switching to mobile Devices */

@media screen and (max-width:680px) {
  #menuButton{
    display: block;
  }

  #navContainer{
    display: none;
  }

}

/* Media Query for Mobile Switching Ends Here */


/* Download Button Goes Here */

.button {
  --main-focus: #2d8cf0;
  --font-color: #323232;
  --bg-color-sub: #dedede;
  --bg-color: #eee;
  --main-color: #323232;
  position: relative;
  width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  background-color: var(--bg-color);
  border-radius: 10px;
  overflow: hidden;
}

.button, .button__icon, .button__text {
  transition: all 0.3s;
}

.button .button__text {
  transform: translateX(22px);
  color: var(--font-color);
  font-weight: 600;
}

.button .button__icon {
  position: absolute;
  transform: translateX(109px);
  height: 100%;
  width: 39px;
  background-color: var(--bg-color-sub);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button .svg {
  width: 20px;
  fill: var(--main-color);
}

.button:hover {
  background: var(--bg-color);
}

.button:hover .button__text {
  color: transparent;
}

.button:hover .button__icon {
  width: 148px;
  transform: translateX(0);
}

.button:active {
  transform: translate(3px, 3px);
  box-shadow: 0px 0px var(--main-color);
}

/* Download Button Ends */


/* Css for Home */

#NameTag{
  color: rgb(0, 255, 251);
}

#home{
  margin-top: 44px;
display: flex;
/* background-color: red; */
}

#context{
margin-left: 30px;
width: 60%;
margin-right: 10px;
/* background-color: blue; */
}

#Description{
font-size: 22px;
max-width:70%;
line-height: 2;
}

#NameTitle{
  text-align: center;
  font-size: 45px;
}

#starttxt{
  display: flex;
  justify-content: center;
  font-size: 23px;
  font-weight: 900;
}

#imgsample{
  margin-right: 20px;
  border-radius: 10px;
}

#Description{
  text-align: center;
  margin: auto;
}

#typedText{
  color: rgb(250, 196, 1);
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
}

.typed-cursor {
  color: rgb(250, 196, 1);
}

/* Home Ends Here */


/* About Section style goes here */

#about{
  display: flex;
  margin-top: 100px;
}

#aboutImg{
  margin-left: 30px;
  border-radius: 10px;
}

#aboutContext{
  /* background-color: red; */
  margin-left: 10px;
  margin-right: 10px;
}

#aboutContext h3{
  text-align: center;
  font-size: 20px;
}

#aboutContext  h1{
  font-size: 45px;
  text-align: center;
  margin-top: 20px;
}

#aboutContext p{
  margin-top: 33px;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  line-height: 2;
  font-size: 22px;
}

/* About Ends Here */

/* Service Card Css Goes Here */

.container .card {
  background-color: white;
  border-radius: 10px;
  border: 1px solid black;
  position: relative;
  z-index: 0;
  transition: all 300ms ease-out;
  transform-origin: center center;
  width: 100%;
  height: 100%;
  box-shadow: 0px;
}

.card-header {
  padding: 10px;
  background-color: #646464;
  color: white;
  font-size: 16px;
  border: 1px solid white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.container .card .border {
  transition: all 300ms ease-out;
  transform-origin: center center;
  position: absolute;
  top: 6px;
  left: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  border: 1px solid black;
  border-radius: 9px;
}

.container {
  perspective: 1000px;
  position: relative;
  /* you don't need width and height if your card already has a lot of content */
  width: 150%;
  max-width: 250px;
  height: 70px;
  border-radius: 10px;
  cursor: pointer;
}
.container a {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 50%;
  height: 50%;
}
.container a:hover,
.container a:focus {
  transform: translateZ(50px);
}

.container a:hover ~ .card .card-header,
.container a:focus ~ .card .card-header {
  transform: translateZ(24px);
}

.container a:nth-child(1) {
  top: 0;
  left: 0;
}
.container a:nth-child(1):hover ~ .card,
.container a:nth-child(1):focus ~ .card {
  transform: rotateX(10deg) rotateY(-10deg) translateZ(0);
  transform-origin: right bottom;
  box-shadow: 10px 20px ;
}
.container a:nth-child(2) {
  top: 0;
  right: 0;
}
.container a:nth-child(2):hover ~ .card,
.container a:nth-child(2):focus ~ .card {
  transform: rotateX(10deg) rotateY(10deg) translateZ(0);
  transform-origin: left bottom;
  box-shadow: -10px 20px;
}
.container a:nth-child(3) {
  bottom: 0;
  right: 0;
}
.container a:nth-child(3):hover ~ .card ,
.container a:nth-child(3):focus ~ .card {
  transform-origin: left top;
  transform: rotateX(-10deg) rotateY(10deg) translateZ(0);
  box-shadow: -10px -20px;
}
.container a:nth-child(4) {
  bottom: 0;
  left: 0;
}
.container a:nth-child(4):hover ~ .card,
.container a:nth-child(4):focus ~ .card {
  transform-origin: right top;
  transform: rotateX(-10deg) rotateY(-10deg) translateZ(0);
  box-shadow: 10px -20px;
}

#serviceCardContain{
  display: flex;
  justify-content: center;
  align-items: center;
}

.card{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Service Card Ends  */


/* Service Section goes here */

#services{
  /* background-color: red; */
  margin-top: 80px;
  margin-left: 30px;
  
}

#services h5{
  font-size: 20px;
}

#services h6{
  font-size: 45px;
}

#serviceBox{
  margin-top: 42px;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Service Ends Here */

/* Explore Card Css Goes Here */
.card {
  display: flex;
  flex-direction: column;
  width: 330px;
  height: 280px;
  max-height: 330px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.08),
    -4px -4px 12px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1),
    -4px -4px 12px rgba(0, 0, 0, 0.08);
}

.card-image-container {
  width: 100%;
  height: 64%;
  border-radius: 10px;
  margin-bottom: 12px;
  overflow: hidden;
  /* background-color: rgb(165, 165, 165); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-image-container img{
  scale: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}


.image-icon {
  font-size: 40px;
}

.card-title {
  margin: 0;
  font-size: 17px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #1797b8;
  cursor: default;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.card-des {
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  margin: 0;
  font-size: 13px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: #1797b8;
  cursor: default;
  text-align: center;
  margin-top: 10px;
}

/* Explore Card Ends */



/* Social Media Contact goes here */

.contactCard {
  display: flex;
  height: 70px;
  width: 270px;
  justify-content: center;
  /* background-color: red; */
}

.contactCard svg {
  position: absolute;
  display: flex;
  width: 60%;
  height: 100%;
  font-size: 24px;
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
  cursor: pointer;
}

.contactCard .social-link1,.contactCard .social-link2,.contactCard .social-link3,.contactCard .social-link4 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  color: whitesmoke;
  font-size: 24px;
  text-decoration: none;
  transition: 0.25s;
  border-radius: 50px;
}

.contactCard svg {
  transform: scale(1);
}

.contactCard .social-link1:hover {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  animation: bounce_613 0.4s linear;
}

.contactCard .social-link2:hover {
  background-color: #3b5998;
  animation: bounce_613 0.4s linear;
}

.contactCard .social-link4:hover {
  background-color: #12a50b;
  animation: bounce_613 0.4s linear;
}

@keyframes bounce_613 {
  40% {
    transform: scale(1.4);
  }

  60% {
    transform: scale(0.8);
  }

  80% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

/* Contact Ends */


/* Explore Section Starts From Here  */

#explore{
  /* background-color: red; */
}

#exploreContext{
  /* background-color: blue; */
  margin-left: 30px;
}

#exploreContext h6{
  font-size: 20px;
}

#exploreContext h4{
  font-size: 45px;
}

#exploreCardBox{
  margin-top: 30px;
  /* background-color: #32b817; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#exploreCardBox .card{
  margin: 20px;
}

/* Explore Ends */


/* Style for Footer Goes here */
#footer{
  background-color: #0c0a09;
  display: flex;
  justify-content: center;
  align-content: center;
}

#footerContext{
  /* background-color: blue; */
  width: 60%;
  margin: 30px;
  padding: 20px;
}

#footerContext img{
  border-radius: 10px;
}

#footerContext p{
  max-width: 60%;
  line-height: 1.6;
  font-size: 19px;
  text-align: left;
  color: #6d6d6d;
}

#footerContact{
  /* background-color: #646464; */
  width: 40%;
  margin: 30px;
  padding: 20px;
  
}

#footerContact h4{
  color: white;
  text-align: center;
  margin-bottom: 30px;
}

#footerContact .contactCard{
  margin: auto;
}

#credits{
  background-color: #0c0a09;
  padding-bottom: 30px;
}

#credits p{
  color: white;
  text-align: center;
  font-size: 15px;
  font-style: normal;
}

/* Footer End */

/* Review Section goes here */

#reviewHeading{
  font-size: 20px;
  margin-left: 30px;
}

#reviewSection{
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

#reviews{
  width: 60%;
  /* background-color: blue; */
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
}

#reviewForm{
  width: 40%;
}

#reviewSubheading{
  font-size: 45px;
  margin-left: 30px;
}

/* Review Section ends Here */


/* Review Form Css Goes Here */

.star{
  font-size: 40px;
  text-align: center;
  margin: 6px;
  cursor: pointer;
  color: rgb(96, 96, 96);
}

/* log out button here */

#button {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica,"sans-serif";
  transition: all .2s;
  padding: 10px 20px;
  border-radius: 100px;
  background: #cfef00;
  border: 1px solid transparent;
  display: none;
  align-items: center;
  font-size: 15px;
}

#button:hover {
  background: #c4e201;
}

#button > svg {
  width: 34px;
  margin-left: 10px;
  transition: transform .3s ease-in-out;
}

#button:hover svg {
  transform: translateX(5px);
}

#button:active {
  transform: scale(0.95);
}



/* log out button ends here  */


.star.filled{
  color: rgb(255, 123, 0);
}

#starContainer{
  /* background-color: red; */
  text-align: center;
}

#formContainer{
  /* background-color: green; */
  display: flex;
  justify-content: center;
  align-items: center;

}

.form-container {
  width: 400px;
  background: linear-gradient(#212121, #212121) padding-box,
              linear-gradient(145deg, transparent 35%,#e81cff, #40c9ff) border-box;
  border: 2px solid transparent;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  margin: 15px;
}

.form-container button:active {
  scale: 0.95;
}

.form-container .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #717171;
  font-weight: 600;
  font-size: 12px;
}

.form-container .form-group input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  background-color: transparent;
  border: 1px solid #414141;
}

.form-container .form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  resize: none;
  color: #fff;
  height: 96px;
  border: 1px solid #414141;
  background-color: transparent;
  font-family: inherit;
}

.form-container .form-group input::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus {
  outline: none;
  border-color: #e81cff;
}

.form-container .form-group textarea:focus {
  outline: none;
  border-color: #e81cff;
}

.form-container .form-submit-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  font-family: inherit;
  color: #717171;
  font-weight: 600;
  width: 40%;
  background: #313131;
  border: 1px solid #414141;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 6px;
}

.form-container .form-submit-btn:hover {
  background-color: #fff;
  border-color: #fff;
}

#btnDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#btnDiv button{
  margin-left: 10px;
  margin-right: 10px;
}


/* Review Form Css Ends Here */


/* Review Card CSS Goes Here */

.notification {
  display: inline-flex;
  flex-direction: column;
  isolation: isolate;
  position: relative;
  width: 18rem;
  height: 8rem;
  background: #29292c;
  border-radius: 1rem;
  margin: 10px;
  overflow: hidden;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 16px;
  --gradient: linear-gradient(to bottom, #2eadff, #3d83ff, #7e61ff);
  --color: #32a6ff
}

  .reviewCardRating{
    color: rgb(96, 96, 96);
    text-align: center;
    font-size: 25px;
  }

  #cardRating{
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reviewCardRating.filled{
    color: rgb(255, 123, 0);
  }

.notification:before {
  position: absolute;
  content: "";
  inset: 0.0625rem;
  border-radius: 0.9375rem;
  background: #18181b;
  z-index: 2
}

.notification:after {
  position: absolute;
  content: "";
  width: 0.25rem;
  inset: 0.65rem auto 0.65rem 0.5rem;
  border-radius: 0.125rem;
  background: var(--gradient);
  transition: transform 300ms ease;
  z-index: 4;
}

.notification:hover:after {
  transform: translateX(0.15rem)
}

.notititle {
  color: var(--color);
  padding: 0.65rem 0.25rem 0.4rem 1.25rem;
  font-weight: 500;
  font-size: 1.1rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notititle {
  transform: translateX(0.15rem)
}

.notibody {
  color: #99999d;
  padding: 0 1.25rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notibody {
  transform: translateX(0.25rem)
}

.notiglow,
.notiborderglow {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle closest-side at center, white, transparent);
  opacity: 0;
  transition: opacity 300ms ease;
}

.notiglow {
  z-index: 3;
}

.notiborderglow {
  z-index: 1;
}

.notification:hover .notiglow {
  opacity: 0.1
}

.notification:hover .notiborderglow {
  opacity: 0.1
}

.note {
  color: var(--color);
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 0.9rem;
  width: 75%;
}

/* Review Card Css Ends HEre  */


/* Css To Make Complete Website Responsive using Media Query */

@media screen and (max-width:1319px) {
  #home img{
    height: 350px;
  }

  #about img{
    height: 350px;
  }
}

@media screen and (max-width:1240px) {
  #starttxt, #aboutContext h3, #services h5, #exploreContext h6, #reviewHeading{
    font-size: 18px;
  }

  #NameTitle, #aboutContext h1, #services h6, #exploreContext h4, #reviewSubheading{
    font-size: 39px;
  }

  #Description, #aboutContext p{
    font-size: 20px;
  }

}


@media screen and (max-width:1171px) {
  #home img, #about img{
    height: 300px;
  }
}

@media screen and (max-width:1103px) {
  #NameTitle, #aboutContext h1, #services h6, #exploreContext h4, #reviewSubheading{
    font-size: 33px;
  }


  #Description, #aboutContext p{
    font-size: 18px;
    line-height: 1.5;
  }

}

@media screen and (max-width:1088px) {
  #reviewSection{
    flex-wrap: wrap-reverse;
  }

  #reviewForm{
    margin-top: 30px;
    width: 100%;
  }

  #reviews{
    margin-top: 30px;
    width: 100%;
    margin-left: 0px;
  }

}

@media screen and (max-width:999px) {
  
  #home img, #about img{
    height: 270px;
  }
}

@media screen and (max-width:956px) {

  #home{
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #about{
    flex-wrap: wrap-reverse;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #context{
    width: 90%;
  }

  #home img, #about img{
    margin-top: 40px;
    height: 400px;
    margin-left: 0px;
    margin-right: 0px;
  }

  #NameTitle{
    margin-left: 0px;
    margin-right: 0px;
  }

  #NameTitle, #aboutContext h1, #services h6, #exploreContext h4, #reviewSubheading{
    font-size: 45px;
  }


  #Description, #aboutContext p{
    font-size: 22px;
    line-height: 2;
  }

  #starttxt, #aboutContext h3, #services h5, #exploreContext h6, #reviewHeading{
    font-size: 20px;
  }
  
}


@media screen and (max-width:822px) {
  
  #home img, #about img{
    margin-top: 40px;
    height: 370px;
    margin-left: 0px;
    margin-right: 0px;
  }

  #NameTitle{
    margin-left: 0px;
    margin-right: 0px;
  }

  #NameTitle, #aboutContext h1, #services h6, #exploreContext h4, #reviewSubheading{
    font-size: 38px;
  }


  #Description, #aboutContext p{
    font-size: 19px;
    line-height: 2;
  }

  #starttxt, #aboutContext h3, #services h5, #exploreContext h6, #reviewHeading{
    font-size: 18px;
  }

}

@media screen and (max-width:777px) {
  
  #home img, #about img{
    margin-top: 40px;
    height: 330px;
    margin-left: 0px;
    margin-right: 0px;
  }

  #NameTitle{
    margin-left: 0px;
    margin-right: 0px;
  }

  
}

@media screen and (max-width:680px) {
  
  #home img, #about img{
    margin-top: 40px;
    height: 290px;
    margin-left: 0px;
    margin-right: 0px;
  }

  #logo{
    width: 60px;
    height: 60px;
  }

  #footer{
    flex-wrap: wrap;
  }

  #footerContact, #footerContext{
    width: 90%;
  }

  #footerContext p{
    width: 100%;
    max-width: 100%;
  }

  #NameTitle, #aboutContext h1, #services h6, #exploreContext h4, #reviewSubheading{
    font-size: 35px;
  }


  #Description, #aboutContext p{
    font-size: 17px;
    line-height: 1.8;
  }

}

@media screen and (max-width:552px) {

  #home img, #about img{
    margin-top: 40px;
    height: 260px;
    margin-left: 0px;
    margin-right: 0px;
  }

  #serviceBox{
    margin-left: 10px;
    margin-right: 10px;
  }
  
}

@media screen and (max-width:498px) {
  
  #home img, #about img{
    margin-top: 40px;
    height: 230px;
    margin-left: 0px;
    margin-right: 0px;
  }

  #NameTitle, #aboutContext h1, #services h6, #exploreContext h4, #reviewSubheading{
    font-size: 31px;
  }

  #starttxt, #aboutContext h3, #services h5, #exploreContext h6, #reviewHeading{
    font-size: 16px;
  }

}


@media screen and (max-width:434px) {
  
  #home img, #about img{
    margin-top: 40px;
    height: 200px;
    margin-left: 0px;
    margin-right: 0px;
  }

  #NameTitle, #aboutContext h1, #services h6, #exploreContext h4, #reviewSubheading{
    font-size: 29px;
  }

  #starttxt, #aboutContext h3, #services h5, #exploreContext h6, #reviewHeading{
    font-size: 15px;
  }

  #Description, #aboutContext p{
    font-size: 15px;
    line-height: 1.9;
  }

}

@media screen and (max-width:393px) {
  
  #home img, #about img{
    margin-top: 40px;
    height: 180px;
    margin-left: 0px;
    margin-right: 0px;
  }

  #serviceBox{
    flex-wrap: wrap;
  }

  #NameTitle, #aboutContext h1, #services h6, #exploreContext h4, #reviewSubheading{
    font-size: 26px;
  }

  #starttxt, #aboutContext h3, #services h5, #exploreContext h6, #reviewHeading{
    font-size: 15px;
  }

  #Description, #aboutContext p{
    font-size: 15px;
    line-height: 1.9;
  }

}

@media screen and (max-width:357px) {
  #home img, #about img{
    margin-top: 40px;
    height: 156px;
    margin-left: 0px;
    margin-right: 0px;
  }

  #NameTitle, #aboutContext h1, #services h6, #exploreContext h4, #reviewSubheading{
    font-size: 23px;
  }

  #starttxt, #aboutContext h3, #services h5, #exploreContext h6, #reviewHeading{
    font-size: 13px;
  }

  #Description, #aboutContext p{
    font-size: 14px;
    line-height: 1.9;
  }

}

@media screen and (max-width:325px) {
  
  #NameTitle, #aboutContext h1, #services h6, #exploreContext h4, #reviewSubheading{
    font-size: 21px;
  }

  #starttxt, #aboutContext h3, #services h5, #exploreContext h6, #reviewHeading{
    font-size: 12px;
  }

  #Description, #aboutContext p{
    font-size: 13px;
    line-height: 1.9;
  }

}
/* Last Code Ended Here */